import '../CSS/ContactUs.css'

function ContactUs() {
    return (
        <div class="container">
            <h1>Join Our Team</h1>
            <p className='contactusP'>Whether you are someone who has always been interested in tax preparation or someone who only wants to work 4 months and still have enough money to pay bills and enjoy life for the rest ofhe year, you've come to the right place!
                <br></br>
            </p>
            <p className='contactusP'>
                Join Freedom Phoenix's Team of Independent Tax Preparers Today!
            </p>
            <form target="_blank" action="https://formsubmit.co/16c22cea637d9a1cb96cedad43b09750" method="POST">
                <div class="form-group">
                    <div class="form-row">
                        <div class="col">
                            <input type="text" name="name" class="form-control" placeholder="Full Name" required></input>
                        </div>
                        <div class="col">
                            <input type="email" name="email" class="form-control" placeholder="Email Address" required></input>
                        </div>
                        <div class="col">
                            <input type="hidden" name="_subject" value="New submission!"></input>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <textarea placeholder="Please Include Your Phone Number" class="form-control" name="message" rows="10" required></textarea>
                </div>
                <button type="submit" class="btn btn-lg btn-dark btn-block">Submit</button>
            </form>


        </div>
    );
}

export default ContactUs;
