import RiverdaleStore from '../MEDIA/RiverdaleFront.jpg'
import { Link } from 'react-router-dom';
import '../CSS/LocationDecatur.css'


function LocationRiverdale() {
    return (

        <div className="fullpage">
            <div className='decaturLocation'>
                <h1 className='storelocation'>
                    Riverdale Location
                </h1>

                <img className='locationimgriverdale' src={RiverdaleStore}></img>
                <div className='locationBox'>
                    <div className='locationInformation'>
                        <div className='addy'>
                            <p>773 Highway 138 SW Suite 2
                                Riverdale, GA 30296
                            </p>
                        </div>
                        <div className='email'>
                            <p>info@myfreedomphoenix.com
                            </p>
                        </div>
                        <div className='officeNumber'>
                            <p>Office: 678-489-6330
                            </p>
                        </div>
                        <div className='faxNumber'>
                            <p> Fax: 888-745-0389
                            </p>
                        </div>
                    </div>
                    <h1 className='contactusHeading'>Contact Us</h1>
                    <form className='form' target="_blank" action="https://formsubmit.co/16c22cea637d9a1cb96cedad43b09750" method="POST">
                        <div class="form-group">
                            <div class="form-row">
                                <div class="col">
                                    <input type="text" name="name" class="form-control" placeholder="Full Name" required></input>
                                </div>
                                <div class="col">
                                    <input type="email" name="email" class="form-control" placeholder="Email Address" required></input>
                                </div>
                                <div class="col">
                                    <input type="hidden" name="_subject" value="New submission!"></input>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <textarea placeholder="Please Include Your Phone Number" class="form-control" name="message" rows="10" required></textarea>
                        </div>
                        <button type="submit" class="btn btn-lg btn-dark btn-block">Submit</button>
                    </form>
                </div>
                <div className='googleMap'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3325.1049151521056!2d-84.41885702376366!3d33.550650344048236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f4fb17cca9d5bd%3A0xca9fdc93c5f0e8cc!2s773%20GA-138%20Suite%202%2C%20Riverdale%2C%20GA%2030296!5e0!3m2!1sen!2sus!4v1700859169924!5m2!1sen!2sus" width="95%" height="88%" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="button">
                    <Link to="/squareBookingCalendar" className="btn btn-primary">Book at Riverdale</Link>
                </div>
            </div>
        </div>


    );
}

export default LocationRiverdale;
