import React from "react";
import '../CSS/Home.css'
import { Link } from 'react-router-dom';
import Carousel from '../JSX/BasicSlider';
import background2 from '../MEDIA/BackgroundOption/fptp_-1.jpg'
import background3 from '../MEDIA/BackgroundOption/fptp_-4.jpg'
import background4 from '../MEDIA/BackgroundOption/fptp_-9.jpg'
import background5 from '../MEDIA/BackgroundOption/Claudia in Decatur.jpg'
import groupPhoto from '../MEDIA/fptp_-13.jpg'

const images = [
    background2,
    background3,
    background4,
    background5,

];

function Home() {
    return (
        <div className="home_fullpage">
            <Carousel images={images} interval={3000} />
            {/* <div className="home_missionStatement_bg"> */}
            <div>

                <h1 className="home_missionStatement_heading">
                    Our Mission is to Empower People Towards Financial Self Sufficiency
                </h1>
            </div>
            <div className="home_missionStatement">
                <p className="home_missionStatement_p" >

                    Freedom Phoenix is a family owned tax preparation company founded and located in Georgia, servicing all 50 states. With over 40 years of experience in US taxation laws, we make it our mission to teach you the strategies to minimize your tax liability. Our goal is to help people empower themselves towards financial success and self sufficiency.
                </p>
                <p className="home_missionStatement_p">
                    We specialize in tax preparation services for both individual and small businesses
                    across any and all industries. Whether you are employed or self-employed, we will dig for deductions, and guide you in future year strategies.

                </p>
            </div>
            {/* </div> */}
            <div className="home_MeetTheTeamCard">
                <img className="groupPhoto" src={groupPhoto}></img>
                <div className="home_MeetTheTeamText">
                    <p className="home_missionStatement_p2">
                        Freedom Phoenix takes pride in how we treat our clients and employees.
                        <br />
                        Here at Freedom Phoenix, everyone is family.

                    </p>
                    <button className='homePageBtn'>
                        <Link className='homePageBtn' to="/aboutUs">
                            Meet The Team
                        </Link>
                    </button>
                </div>

            </div>
            {/* <div className="home_ServiceOptions_BG"> */}
            <div className="home_ServiceOptions">
                <div className="homepgbtn_card">
                    <button className='homePageBtn'>
                        <Link className='homePageBtn' to="/squareBookingCalendar">
                            Tax Preparation
                        </Link>
                    </button>
                    <p className="homePageBtn_p">We dig for deductions that other companies won't! Let us decrease your tax liability and get the maximum tax refund. We offer both in office and virtual appointments. Book with us today</p>
                </div>
                <div className="homepgbtn_card">
                    <button className='homePageBtn'>
                        <Link className='homePageBtn' to="/squareBookingCalendar">
                            Free Estimates
                        </Link>
                    </button>
                    <p className="homePageBtn_p">Not sure if we’re the right tax company for you? We offer free estimates to all clients. Know your refund and bill before making the decision to file with us.</p>
                </div>
                <div className="homepgbtn_card">
                    <button className='homePageBtn'>
                        <Link className='homePageBtn' to="/squareBookingCalendar">
                            1099 Filing and Reporting
                        </Link>
                    </button>
                    <p className="homePageBtn_p">Does your business need help filing 1099 forms for your independent contractors? We can assist with that!</p>
                </div>
                <div className="homepgbtn_card">

                    <button className='homePageBtn'>
                        <Link className='homePageBtn' to={"https://www.drknowitali.com/"}>
                            Business SetUp Assistance
                        </Link>
                    </button>
                    <p className="homePageBtn_p">If you are interested in starting a business, but don’t know where to start, book with our associate, DrKnowItAli for all the answers to your questions</p>
                </div>
                <div>

                    <button className='homePageBtn'>
                        <Link className='homePageBtn' to="/services">
                            Full List of Services
                        </Link>
                    </button>
                </div>
            </div>
            {/* </div> */}
            <div className="home_JobOptions">
                <div>
                    <h1 className="home_JobOptions_title"> Interested In Preparing Taxes Professionally?</h1>
                </div>
                <button className='homePageBtn'>
                    <Link className='homePageBtn' to="/contactUs">
                        Join The Team
                    </Link>
                </button>
            </div>

        </div >
    )
}
export default Home;