import React from 'react';

const SquareBookingDecaturCalendar = () => {
    return (
        <div>
            <iframe
                title="Square Booking Calendar"
                src='https://squareup.com/appointments/book/qgwts5znn13k84/LDKRBG917G4TC/services'
                width="100%"
                height="600"
                frameBorder="0"
            />
        </div>
    );
};

export default SquareBookingDecaturCalendar;
