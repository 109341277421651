import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "../CSS/NavigationBar.css";
import logo from '../MEDIA/MyFreedomPhoenixLogo.png';

export default function NavigationBar() {
    const [dropdowns, setDropdowns] = useState({
        home: false,
        services: false,
        about: false,
        contact: false
    });

    const toggleDropdown = (dropdown) => {
        setDropdowns((prevDropdowns) => ({
            ...prevDropdowns,
            [dropdown]: !prevDropdowns[dropdown]
        }));
    };

    return (
        <nav className="navbar_nav">

            <Link to="/">
                <img href="/" className="navbar_navbarlogo" src={logo} alt="Logo" />
            </Link>

            <div className='navbar_nav-rightBracket'>
                <div className="navbar_dropdown">
                    <Link className='navbar_btn' onClick={() => toggleDropdown('home')}>
                        <Link className='navbar_btn' to="/">
                            Home
                        </Link>
                    </Link>

                </div>

                <div className="navbar_dropdown">
                    <Link className='navbar_btn' to='/services' >
                        Services
                    </Link>
                    {/* {dropdowns.services && (
                        <div className="navbar_dropdown-content">
                            <Link to="/">Tax Preparation</Link>
                            <Link to="/">Link 1</Link>
                            <Link to="/">Link 2</Link>
                            <Link to="/">Link 3</Link>
                        </div>
                    )} */}
                </div>

                <div className="navbar_dropdown">
                    <Link className='navbar_btn' to='/aboutUs' >
                        About Us
                    </Link>
                    {/* {dropdowns.about && (
                        <div className="navbar_dropdown-content">
                            <Link to="/">Link 4</Link>
                            <Link to="/">Link 5</Link>
                        </div>
                    )} */}
                </div>

                <div className="navbar_dropdown">
                    <Link className='navbar_btn' onClick={() => toggleDropdown('contact')}>
                        Contact Us
                    </Link>
                    {dropdowns.contact && (
                        <div className="navbar_dropdown-content">
                            <Link to="/locationRiverdale">Riverdale Location</Link>
                            <Link to="/locationDecatur">Decatur Location</Link>
                            <Link to="/contactUs">Join Our Team</Link>
                        </div>
                    )}
                </div>

            </div>
        </nav>
    );
}
