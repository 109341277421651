import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import NavigationBar from "./JSX/NavigationBar";
import Footer from "./JSX/Footer";
import Home from "./JSX/Home";
import Services from "./JSX/Services";
import AboutUs from "./JSX/AboutUs";
import ContactUs from "./JSX/ContactUs";
import LocationDecatur from "./JSX/LocationDecatur";
import LocationRiverdale from "./JSX/LocationRiverdale";
import SquareBookingCalendar from "./JSX/SquareBooking";
import SquareBookingCalendarDecatur from "./JSX/SquareBookingDecatur";


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <NavigationBar />
        <Routes>
            // <Route path='/' element={<Home />} />
          <Route path='/services' element={<Services />} />
          <Route path='/aboutUs' element={<AboutUs />} />
          <Route path='/contactUs' element={<ContactUs />} />
          <Route path='/locationDecatur' element={<LocationDecatur />} />
          <Route path='/locationRiverdale' element={<LocationRiverdale />} />
          <Route path='/squareBookingCalendar' element={<SquareBookingCalendar />} />
          <Route path='/squareBookingCalendarDecatur' element={<SquareBookingCalendarDecatur />} />
          {/* 
            <Route path='/joinOurTeam' element={<JoinOurTeam />} />
            <Route path='/servicesList' element={<ServicesList />} />
            <Route path='/BioPageDrAli' element={<BioPageDrAli />} />
            <Route path='/BioPageZarina' element={<BioPageZarina />} />
            <Route path='/BioPageTahirah' element={<BioPageTahirah />} />
            <Route path='/BioPageClaudia' element={<BioPageClaudia />} />
             */}
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
