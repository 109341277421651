import DecaturStore from '../MEDIA/DecaturFront.jpg'
import { Link } from 'react-router-dom';
import '../CSS/LocationDecatur.css'


function Locations() {
    return (

        <div className="fullpage">
            <div className='decaturLocation'>
                <h1 className='storelocation'>
                    Decatur Location
                </h1>

                <img className='locationimg' src={DecaturStore}></img>
                <div className='locationBox'>
                    <div className='locationInformation'>
                        <div className='addy'>
                            <p>4421 Glenwood Rd Suite 600, Decatur, GA 30032
                            </p>
                        </div>
                        <div className='email'>
                            <p>decatur_office@myfreedomphoenix.com
                            </p>
                        </div>
                        <div className='officeNumber'>
                            <p>Office: 404-205-5399
                            </p>
                        </div>
                        <div className='faxNumber'>
                            <p> Fax: 800-709-5024
                            </p>
                        </div>
                    </div>
                    <h1 className='contactusHeading'>Contact Us</h1>
                    <form className='form' target="_blank" action="https://formsubmit.co/16c22cea637d9a1cb96cedad43b09750" method="POST">
                        <div class="form-group">
                            <div class="form-row">
                                <div class="col">
                                    <input type="text" name="name" class="form-control" placeholder="Full Name" required></input>
                                </div>
                                <div class="col">
                                    <input type="email" name="email" class="form-control" placeholder="Email Address" required></input>
                                </div>
                                <div class="col">
                                    <input type="hidden" name="_subject" value="New submission!"></input>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <textarea placeholder="Please Include Your Phone Number" class="form-control" name="message" rows="10" required></textarea>
                        </div>
                        <button type="submit" class="btn btn-lg btn-dark btn-block">Submit</button>
                    </form>
                </div>
                <div className='googleMap'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13271.652115393128!2d-84.2374703!3d33.737065!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5abe0d38acb4f%3A0x4daa1b2a33fd670!2sFREEDOM%20PHOENIX!5e0!3m2!1sen!2sus!4v1703688464288!5m2!1sen!2sus" width="95%" height="68%" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div className="button">
                    <Link to="/squareBookingCalendarDecatur" className="btn btn-primary">Book at Decatur</Link>
                </div>
            </div>
        </div>


    );
}

export default Locations;
