import { Link } from "react-router-dom";
import "../CSS/Footer.css"
import FacebookIcon from "../MEDIA/facebook_1051258.png"
import InstagramIcon from "../MEDIA/instagram_1400845.png"
import TikTokIcon from "../MEDIA/tik-tok_3046126.png"
import logo from "../MEDIA/MyFreedomPhoenixLogo.png"

let Footer = () => {
    return (
        <footer className="footer_navWrapper">

            <div className="footer_introDiv">
                <Link to="/">
                    <img href="/" className="footer_navbarlogo" src={logo}></img>
                </Link>
                <h1 className="footer_h1">FREEDOM PHOENIX TAX SOLUTIONS</h1>
                <div className="footer_emptySpace"></div>
            </div>

            <h3 className="footer_h3">Quick Links</h3>

            <div className="footer_socialMediaBox">

                <div>
                    <Link className="footer_navLink" to={"https://www.facebook.com/freedomphoenixinc"}><img className="footer_smtag" src={FacebookIcon} height={50} width={50}></img></Link>
                </div>
                <div>
                    <Link className="footer_navLink" to={"https://www.instagram.com/explore/locations/186378398375061/freedom-phoenix/"}><img className="footer_smtag" src={InstagramIcon} height={50} width={50}></img></Link>
                </div>
                <div>
                    <Link className="footer_navLink" to={"https://www.tiktok.com/@freedomphoenixtax"}><img className="footer_smtag" src={TikTokIcon} height={50} width={50}></img></Link>
                </div>
                {/* <Link className="footer_navLink" to={"https://www.facebook.com/freedomphoenixinc"}><img src={YoutubeIcon} height={50} width={50}></img></Link> */}
            </div>

            <div className="footer_listOfLinks">


                <nav className="footer_navBtnWrapper">
                    <Link className="footer_navLink" to={"/"}>Home</Link>
                    <Link className="footer_navLink" to={"/aboutUs"}>AboutUs</Link>
                    <Link className="footer_navLink" to={"/locationRiverdale"}>Riverdale Location</Link>
                    <Link className="footer_navLink" to={"/locationDecatur"}>Decatur Location</Link>
                    <Link className="footer_navLink" to={"/services"}>Services</Link>
                    <Link className="footer_navLink" to={"/contactUs"}>JoinOurTeam</Link>
                    {/* <Link className="footer_navLink" to={"/contactUs"}>ContactUs</Link> */}
                </nav>

                <nav className="footer_navBtnWrapper">
                    <Link className="footer_navLink" to={"https://www.taxpassapp.com/?taxpassid=NYSE8JXH#/login"}>RIVERDALE CLIENT Tax Pass LogIn</Link>
                    <Link className="footer_navLink" to={"https://www.taxpassapp.com/?taxpassid=LBYF2N6B#/login"}>DECATUR CLIENT Tax Pass LogIn</Link>
                    <Link className="footer_navLink" to={"https://www.drknowitali.com/"}>Our Affiliates</Link>
                    <Link className="footer_navLink" to={"https://www.google.com/search?q=freedom+phoenix+tax&sca_esv=600777061&sxsrf=ACQVn09hCOynEZaZEklMLINcn5QgDydWdQ%3A1706027945839&source=hp&ei=qeuvZfb3MK75kPIP2aar8As&iflsig=ANes7DEAAAAAZa_5uU4gpv131doIJZzbGNrE3L0RyaCK&gs_ssp=eJzj4tFP1zc0MjOOTzfLTTFgtFI1qLCwSDNJSzI0T05ONU41NTa1MqgwSbRMMTNPSk1KNDC2MDCx9BJOK0pNTcnPVSjIyE_Ny6xQKEmsAAAKVxZR&oq=freedom&gs_lp=Egdnd3Mtd2l6IgdmcmVlZG9tKgIIADITEC4YgAQYigUYxwEYrwEYjgUYJzIEECMYJzIEECMYJzIEECMYJzIXEC4YgAQYigUYkQIYsQMYgwEYxwEY0QMyFBAuGIAEGLEDGIMBGMcBGK8BGI4FMgsQABiABBixAxiDATILEAAYgAQYsQMYgwEyCxAuGIAEGMcBGK8BMhEQLhiABBixAxiDARjHARjRA0iKElAAWNAGcAB4AJABAJgBZKABzQSqAQM2LjG4AQPIAQD4AQHCAgoQIxiABBiKBRgnwgILEAAYgAQYigUYkQLCAggQABiABBixA8ICDhAuGIAEGIoFGLEDGIMBwgIFEC4YgATCAhEQLhiDARjHARixAxjRAxiABMICDhAAGIAEGIoFGLEDGIMB&sclient=gws-wiz#ip=1&lrd=0x88f4fb17cce3e535:0x4a9d67beba038049,1,,,,"}>Google Reviews</Link>
                    <Link className="footer_navLink" to={"https://sa.www4.irs.gov/wmr/"}>Check Your Refund Status</Link>

                </nav>

            </div>

            <div className='footer_footer_locationBox'>
                <div className='footer_locationInformation'>
                    <div className='footer_addy'>
                        <p>773 Highway 138 Suite 2
                            Riverdale, GA 30296
                        </p>
                    </div>
                    <div className='footer_email'>
                        <p>info@myfreedomphoenix.com
                        </p>
                    </div>
                    <div className='footer_officeNumber'>
                        <p>Office: 678-489-6330

                        </p>
                    </div>
                    <div className='footer_faxNumber'>
                        <p> Fax: 800-709-5024
                        </p>
                    </div>

                </div>

                <div >
                    <iframe className='footer_themap' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3325.1049151521056!2d-84.41885702376366!3d33.550650344048236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f4fb17cca9d5bd%3A0xca9fdc93c5f0e8cc!2s773%20GA-138%20Suite%202%2C%20Riverdale%2C%20GA%2030296!5e0!3m2!1sen!2sus!4v1700859169924!5m2!1sen!2sus"

                        // width="400" height="160"

                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </footer>
    )
}

export default Footer;