import React from "react";
import '../CSS/Home.css';
import '../CSS/Services.css'
import Image1 from '../MEDIA/Untitled design/1.jpg'
import Image2 from '../MEDIA/Untitled design/2.jpg'
import Image3 from '../MEDIA/Untitled design/3.jpg'
import Image4 from '../MEDIA/Untitled design/4.jpg'
import Image5 from '../MEDIA/Untitled design/5.jpg'
import Image6 from '../MEDIA/Untitled design/6.jpg'
import Image7 from '../MEDIA/Untitled design/7.jpg'
import Image8 from '../MEDIA/Untitled design/8.jpg'
import Image9 from '../MEDIA/Untitled design/9.jpg'
import TPG from '../MEDIA/TPG_Logo.png'
import Refundo from '../MEDIA/New-logo-refundo-color.jpg'
import Carousel2 from "../JSX/BasicSlider2";

const images = [
    TPG,
    Refundo,
];

function Services() {
    return (
        <div className="services_fullPage">

            <div className="services_smallcard">
                <div className="smallcard_ribbon">
                    <h1 className="smallcard_heading">

                        Personal Tax Preparation
                    </h1>
                    <img className="ribbon_image" src={Image1}></img>
                </div>
                <div className="smallcard_block">
                    <p>
                        Assistance in the preparation and filing of individual income taxes. We make it our
                        mission to get you the highest refund/lowest liability.
                    </p>
                </div>
            </div>
            <div className="services_smallcard">
                <div className="smallcard_ribbon">
                    <h1 className="smallcard_heading">

                        Business Tax Preparation
                    </h1>
                    <img className="ribbon_image" src={Image2}></img>
                </div>
                <div className="smallcard_block">
                    <p>
                        Assistance and advisement in the preparation and filing of business returns of all types
                        (1065, 1041, 990, etc).
                    </p>
                </div>
            </div>
            <div className="services_smallcard">
                <div className="smallcard_ribbon">
                    <h1 className="smallcard_heading">

                        Free Tax Prep Estimate
                    </h1>
                    <img className="ribbon_image" src={Image3}></img>
                </div>
                <div className="smallcard_block">
                    <p>
                        Know your potential refund and bill before making the decision to file with us.
                    </p>
                </div>
            </div>
            <div className="services_smallcard">
                <div className="smallcard_ribbon">
                    <h1 className="smallcard_heading">

                        Tax Consultation
                    </h1>
                    <img className="ribbon_image" src={Image4}></img>
                </div>
                <div className="smallcard_block">
                    <p>
                        We will never fit you in a box or lump you with the rest. When you schedule a
                        consultation with us, you will get help with strategizing for current and following tax years
                        based on your own individual tax situation.
                    </p>
                </div>
            </div>
            <div className="services_smallcard">
                <div className="smallcard_ribbon">
                    <h1 className="smallcard_heading">

                        Offers in compromise
                    </h1>
                    <img className="ribbon_image" src={Image5}></img>
                </div>
                <div className="smallcard_block">
                    <p>
                        Assistance in applying to settle your tax debt for less than the full amount you owe.
                    </p>
                </div>
            </div>
            <div className="services_smallcard">
                <div className="smallcard_ribbon">
                    <h1 className="smallcard_heading">

                        Payment Plans
                    </h1>
                    <img className="ribbon_image" src={Image6}></img>
                </div>
                <div className="smallcard_block">
                    <p>
                        Assistance with setting up payment plans with the IRS and state.
                    </p>
                </div>
            </div>
            <div className="services_smallcard">
                <div className="smallcard_ribbon">
                    <h1 className="smallcard_heading">

                        Bookkeeping
                    </h1>
                    <img className="ribbon_image" src={Image7}></img>
                </div>
                <div className="smallcard_block">
                    <p>
                        Our services don’t stop at taxes. We also offer bookkeeping services for our clients with
                        businesses or self employment income. This service is only available to current tax
                        clients.
                    </p>
                </div>
            </div>


            <div className="services_smallcard">
                <div className="smallcard_ribbon">
                    <h1 className="smallcard_heading">

                        Amendments
                    </h1>
                    <img className="ribbon_image" src={Image8}></img>
                </div>
                <div className="smallcard_block">
                    <p>
                        We can assist with correcting any errors or make changes to your original return.

                    </p>
                </div>
            </div>
            <div className="services_smallcard">
                <div className="smallcard_ribbon">
                    <h1 className="smallcard_heading">

                        Notary Public
                    </h1>
                    <img className="ribbon_image" src={Image9}></img>
                </div>
                <div className="smallcard_block">
                    <p>
                        Don’t have the time to come to us for notary public services? No worries! We’ll travel to you!

                    </p>
                </div>
            </div>
            <div className="services_smallcard">
                <div className="smallcard_ribbon">
                    <h1 className="smallcard_heading">

                        1099 Filing and Reporting
                    </h1>
                    <img className="ribbon_image" src={Image7}></img>
                </div>
                <div className="smallcard_block">
                    <p>
                        If your business needs help filling 1099s for your independent contractors, we’ve got you covered!

                    </p>
                </div>
            </div>
            {/* <div className="super_services_smallcard">
                <div className="super_smallcard_ribbon">
                    <h1 className="super_smallcard_heading">
                        Amendments
                    </h1>
                    <img className="ribbon_image" src={Image8}></img>
                </div>
                <div className="super_smallcard_block">
                    <p>
                        we can assist with correcting any errors or make changes to your original return.

                    </p>
                </div>
            </div>
            <div className="super_services_smallcard">
                <div className="super_smallcard_ribbon">
                    <h1 className="super_smallcard_heading">
                        Notary Public
                    </h1>
                    <img className="ribbon_image" src={Image9}></img>
                </div>
            </div>
            <div className="super_services_smallcard">
                <div className="super_smallcard_ribbon">
                    <h1 className="super_smallcard_heading">
                        1099 Filing and Reporting
                    </h1>
                    <img className="ribbon_image" src={Image9}></img>
                </div>
            </div> */}

            <div className="extras_section">

                <div className="services_bigcard">
                    <h1 className="bigcard_heading">

                        Tax Issues And Audits
                    </h1>
                    <div className="bigcard_block">
                        <p className="bigcard_p">
                            All tax issues, including audits, are handled by our partnering corporation, Freedom
                            Phoenix Tax Clinic Corp.
                        </p>
                    </div>
                </div>
                <div>

                    <div className="services_bigcard">
                        <h1 className="bigcard_heading">
                            Business Structure and Startup** (handled by our associate, DrKnowItAli)
                        </h1>
                        <div className="bigcard_block">
                            <p className="bigcard_p">
                                Our associate, DrKnowItAli, is a business, tax, and financial coach. She can help with all
                                things business related. Schedule an appointment with her today for a business
                                consultation!
                            </p>
                        </div>
                    </div>
                </div>
                <div>
                    <h1 className="partneredBanks">Our Partnered Banks</h1>
                </div>
                <div>
                    <Carousel2 images={images} interval={3000} />
                </div>
            </div>

        </div>

    );
}
export default Services;