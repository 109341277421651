import React, { useState, useEffect } from "react";
import "../CSS/BasicSlider.css";
const Carousel = ({ images, interval = 5000 }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const autoSlide = setInterval(() => {
            setCurrentSlide((currentSlide + 1) % images.length);
        }, interval);

        return () => {
            clearInterval(autoSlide); // Cleanup the interval on component unmount
        };
    }, [currentSlide, images, interval]);

    return (<>
        <div className="carousel-container">
            <div className="carousel" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                {images.map((image, index) => (
                    <div key={index} className="slide">
                        <img className="passed-images" src={image} alt={`Slide ${index + 1}`} />
                    </div>
                ))}
            </div>
        </div>

    </>

    );
};

export default Carousel;
