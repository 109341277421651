import React from "react";
import Image1 from '../MEDIA/BIGBOSS1.jpg';
import Image2 from '../MEDIA/fptp_-16.jpg';
import Image3 from '../MEDIA/fptp_-17.jpg';
import Image4 from '../MEDIA/fptp_-20.jpg';

import '../CSS/AboutUs.css';




function AboutUs() {


    return (
        <div className="overarching">
            <div className="aboutUs_AboutUs">
            </div>
            <div className="aboutUs_fullpage">

                <div className="bioCard">
                    <div className="bioCard_banner">
                        <p className="bio_drali">
                            Dr. Suraiya Shaik Ali, a Ph.D graduate, a financial coach, Executive Director of a non-profit serving the needs of those most disadvantaged on tax issues, and CFO/President of Freedom Phoenix Inc, a tax preparation company, has devoted her life’s work to educating people on how to empower themselves towards financial self sufficiency and success. Dr. Ali was born in Singapore, but “grew up” in the US beginning in 1979. She first came to San Francisco Bay Area, and fell in love with the landscape and the political climate. She is a firm believer in true democracy where people have a real voice in government. Dr. Ali has always been politically inclined and wanted to attend university to study law. Here are her words; “I learned the tax code in my early twenties when I wiped out my husband's IRS debt. I use to help youngsters and elderly file their tax returns themselves whether on paper or over the phone; yes, back then you can file your 1040EZ over the phone. Then in 1996, during my toughest period in life, my guardian angel in Vermont suggested I charge people for doing their taxes. That first client's bill was $15. After that, it seems the universe kept me in the tax business as I explored other careers and pursued my academic goal. In 2008, my son suggested I take the tax business to the next level. He came up with the name Freedom Phoenix because his mother was a phoenix rising to financial freedom with a Ph.D under her belt. For 7 years this was a dream. It was not until 2015 when Freedom Phoenix became a reality”.
                        </p>

                        <img className="bioCard_image" src={Image1}></img>
                    </div>
                    <h1 className="bioCard_name"> Dr. Suraiya
                        <br />Shaik Ali</h1>
                </div>
                <div className="bioCard_mid">
                    <div className="bioCard_mid_banner">
                        <p className="bio_zarina">
                            Zarina Williams, seasoned tax professional with over 10 years of experience, currently holds the esteemed position of Vice President and CEO of Freedom Phoenix Incorporated. She serves as the dedicated General Manager of Freedom Phoenix Riverdale, bringing a wealth of expertise to the field. Zarina's journey into the tax industry began under the mentorship of Dr. Ali, where she cultivated her skills as a preparer while holding a managerial position with a prominent hotel chain. Despite facing unfortunate events that led to the loss of her job, Zarina was determined not to work for someone else so it was an easy decision when Dr Ali suggested opening a storefront tax office preparation office. She has been a stalwart presence with the Riverdale office since its inception in 2015. Her commitment and resilience showcase not only her leadership in the tax sector but also her ability to navigate challenges with grace and determination. Zarina Williams stands as a testament to the power of experience, mentorship, and unwavering dedication.

                        </p>
                        <img className="bioCard_mid_image" src={Image3}></img>
                    </div>
                    <h1 className="bioCard_mid_name"> Zarina Williams</h1>

                </div>
                <div className="bioCard_mid">
                    <div className="bioCard_mid_banner">
                        <p className="bio_claudia">
                            Claudia Arias, General Manager of Freedom Phoenix Decatur, embarked on her journey into tax preparation after facing job loss at the same time as her godsister and sister-in-law. Initially transitioning into taxes as an independent preparer, she was able to get back into a position within the restaurant industry and prepare taxes as a side hustle. Claudia's dedication and skills quickly made her an integral part of the team, leading her to a full-time role with Freedom Phoenix after the challenges posed by the COVID-19 pandemic. Beyond her financial prowess, she holds an Associates degree in Culinary Arts and is a gifted baker. Her entrepreneurial spirit shines through a flourishing small business, with dreams of expanding into a patisserie in the future.
                        </p>
                        <img className="bioCard_mid_image" src={Image2}></img>
                    </div>

                    <h1 className="bioCard_mid_name"> Claudia Arias</h1>
                </div>
                <div className="bioCard_mid">
                    <div className="bioCard_mid_banner">
                        <p className="bio_bighead">
                            Tahirah Williams has been a member of our team since opening day. Initially working as a receptionist at age 14, she has been an important part of the team. Playing many roles throughout the years, she was able to work her way up to her current role as Office Manager at our Riverdale location. Tahirah is a multifaceted and self driven woman. Utilizing her background in business, from her time studying at Clayton State University’s College of Business, she started a nonprofit cooperative focused around homeschool education. Today, in addition to her job with Freedom Phoenix, Tahirah works alongside Dr. Ali, as a personal assistant and producer for her podcast and blog site: “Dr. KnowItAli”. In her free time, she enjoys reading self improvement books, sketching, baking, and traveling.
                        </p>
                        <img className="bioCard_mid_image_bighead" src={Image4}></img>
                    </div>
                    <h1 className="bioCard_mid_name"> Tahirah Williams</h1>
                </div>
            </div>
        </div >

    );
}

export default AboutUs;
