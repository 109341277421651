import React from 'react';

const SquareBookingCalendar = () => {
    return (
        <div>
            <iframe
                title="Square Booking Calendar"
                src="https://squareup.com/appointments/book/8454h5md6r1xb1/7TDCM3XXDJ1SK/services"
                width="100%"
                height="600"
                frameBorder="0"
            />
        </div>
    );
};

export default SquareBookingCalendar;
